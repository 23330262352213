import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Impressum / Datenschutz" />
    <h1>Impressum / Datenschutz</h1>
    <p>
      <br/>
      Ein Projekt des Stadtmuseum Bonn und des fringe ensemble<br/>
      <br/>
      Konzept: Stadtmuseum Bonn und fringe ensemble<br/>
      Projektleitung: fringe ensemble<br/>
      <br/>
      www.citystories-bnx.de<br/>
      mail@citystories-bnx.de<br/>
      <br/>
      Das Projekt CITY STORIES wurde entwickelt im Rahmen von „dive in. Programm für digitale Interaktionen“ der Kulturstiftung des Bundes, gefördert durch die Beauftragte der Bundesregierung für Kultur und Medien (BKM) im Programm NEUSTART KULTUR.<br/>
      <br/>
      <h2>Team</h2>
      Konzept: <strong>Frank Heuel, Svenja Pauka, Annika Ley, Fehime Seven, Dr. Philipp Hoffmann, Yvonne Katzy</strong><br/>
      Leitung: <strong>Frank Heuel, Svenja Pauka</strong><br/>
      Assistenz: <strong>Lisa August</strong><br/>
      App-Programmierung: <strong>Fehime Seven</strong><br/>
      Website: <strong>Martin Wisniowski</strong><br/>
      Design: <strong>Annika Ley</strong><br/>
      Ton: <strong>Andreas Meidinger, Lisa August</strong><br/>
      Postproduktion: <strong>Andreas Meidinger</strong><br/>
      Öffentlichkeitsarbeit: <strong>Claudia Grönemeyer</strong><br/>
      Produktionsleitung: <strong>Jennifer Merten</strong><br/>
      Interviewarbeit: <strong>Bettina Marugg, Philip Schlomm, Svenja Pauka, Claudia Grönemeyer, Andreas Meidinger, Lisa August</strong><br/>
      Sprechende: <strong>Bettina Marugg, Julia Hoffstädter, Nicole Kersten, Justine Hauer, Andreas Meidinger, Philip Schlomm, David Fischer, Frank Heuel</strong><br/>
      Übersetzung: <strong><a href="https://www.sprachgewaltig.de">www.sprachgewaltig.de</a></strong><br/>
      <br/>
      Inhaltlich Verantwortlich gemäß § 55 ABS.2 RStV:<br/>
      Name: fringe ensemble / Gold GbR<br/>
      Anschrift: Goethestraße 31, 53113 Bonn<br/>
      Rechtsform: GbR<br/>
      Email: info@fringe-ensemble.de<br/>
      <br/>
      Hinweise zum Inhalt / Haftungsausschluss
      <br/><br/>
      Das Stadtmuseum und das fringe ensemble haben stets die Richtigkeit, Vollständigkeit und Aktualität der in dieser Internetpräsenz / in dieser App bereitgestellten Informationen zum Ziel. Dennoch können Fehler und Unklarheiten nicht ausgeschlossen werden.
      <br/><br/>
      Daher übernehmen wir weder Gewähr für die Aktualität, Richtigkeit, Vollständigkeit, Qualität und jederzeitige Verfügbarkeit der bereitgestellten Informationen noch für das Auftreten anderweitiger technischer Störungen.
      <br/><br/>
      Für Schäden, die durch die Nutzung der App und der zur Verfügung gestellten Informationen oder Daten oder durch die Nutzung fehlerhafter und/oder unvollständiger Informationen oder Daten verursacht werden, haften wir nicht, sofern nicht vorsätzliches oder grob fahrlässiges Verschulden zur Last fällt.
      <br/><br/>
      Wir behalten uns ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
      <br/><br/>
      Hinweise zu Verweisen und Link
      <br/><br/>
      Diese Webseite / App enthält auch entsprechend gekennzeichnete Links oder Verweise auf Webseiten Dritter. Die Verantwortlichkeit für diese fremden Inhalte liegt alleine bei dem Anbieter, der die Inhalte bereithält. Wir vermitteln lediglich den Zugang zur Nutzung dieser Inhalte.
      <br/><br/>
      Wir haben derzeit keine positiven Kenntnisse über rechtswidrige oder anstößige Inhalte auf den über Link verknüpften Seiten fremder Anbieter. Sollten dennoch auf den verknüpften Seiten fremder Anbieter rechtswidrige oder anstößige Inhalte enthalten sein, so distanzieren wir uns von diesen Inhalten ausdrücklich.
      <br/><br/>
      Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere Schäden, die aus der Nutzung oder Nichtnutzung von Informationen Dritter entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
      <br/><br/>
      Hinweise zum Urheberrecht
      <br/><br/>
      Die in diesem Dienst wiedergegebenen Texte, Bilder und audiovisuellen Inhalte sowie das Layout und Design sind urheberrechtlich geschützt und dürfen ohne unsere vorherige Zustimmung, bzw. der Zustimmung der Rechteinhaber, nicht zu anderen als rein privaten Zwecken verwendet werden. Insbesondere ist eine öffentliche Wiedergabe oder Veränderung der Inhalte unzulässig. Einzelne Inhalte können spezielle Urheberrechtsvermerke enthalten, die zu beachten sind (z.B. im jeweiligen Alternativtext).
      <h2>Datenschutz</h2>
      Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung:<br/><br/>
      Beim Besuch der Website<br/><br/>
      Beim Aufrufen unserer Website werden durch den auf Deinem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert.<br/><br/>
      Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:<br/><br/>
      - Datum und Uhrzeit des Zugriffs<br/>
      - Name und URL der abgerufenen Datei<br/>
      - Website, von der aus der Zugriff erfolgt (Referrer-URL)<br/>
      - verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers<br/>
      - deine Internet Protocol (IP) Addresse<br/><br/>
      Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:<br/><br/>
      - Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,<br/>
      - Gewährleistung einer komfortablen Nutzung unserer Website,<br/>
      - Auswertung der Systemsicherheit und -stabilität sowie<br/>
      - statistischer Auswertung, um Website und den ablauf er Webserver zu verbessern zu weiteren administrativen Zwecken<br/><br/>
      Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
   </p>
  </Layout>
)

export const query = graphql`
  query 
  { 
    allMdx {
      nodes {
        frontmatter {
          title
        }
        id
        body
        slug
      }
    }
  }
`

export default IndexPage
